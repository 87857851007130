import React, { useState, useEffect } from 'react'
import { useGraphic } from '../../../core/utils/graphic-context';

const Component = ({ registry, events, ...args }) => {
    const [style, setStyle] = useState();
    const [text, setText] = useState();
    const [visible, setVisible] = useState(true);
    const { config } = useGraphic();


    useEffect(() => {
        if (args.code) {
            if (config && config.overrideProperties && config.overrideProperties.Text && Array.isArray(config.overrideProperties.Text)) {
                config.overrideProperties.Text.forEach((override) => {
                    if (override.code === args.code) {
                        if (override.style) {
                            setStyle(override.style);
                        }
                        if (override.text) {
                            setText(override.text);
                        }
                        if (override.visible) {
                            setVisible(override.visible);
                        }
                    }
                });

            } else {
                setText(args.text || '');
                setStyle(args.style || 'info');

            }
        }

    }, [config, args]);

    if (args.code) {

        const api = {};
        Object.defineProperties(api, {
            'style': {
                get: () => style,
                set: (v) => setStyle(v)

            },
            'text': {
                get: () => text,
                set: (v) => setText(v)

            },
            'visible': {
                get: () => visible,
                set: (v) => setVisible(v)
            },
        });
        registry.set(args.code, api);

    }

    const styleCfg = {
        alert: {
            color: 'var(--error)',
            fontSize: '14px',
            whiteSpace: 'normal',
        },
        info: {
            color: 'var(--secondary)',
            fontSize: '14px',
            whiteSpace: 'normal',
            textAlign: 'center'
        },
        normal: {
            color: 'var(--primary)',
            fontSize: '14px',
            whiteSpace: 'normal',
            textAlign: 'center'
        }
    }

    if (!visible) return <></>
    return <div style={styleCfg[style] || styleCfg.normal}>
        {text}</div>
}

export default Component;
