import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./core/utils/reportWebVitals";
import { apiUrl } from "./core/services";
import "./index.scss";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    enabled: process.env.REACT_APP_SENTRY_ENABLED === "true",
    dsn: "https://09f38e158af94039b11fbcfc3ea158d2@o1258815.ingest.sentry.io/4504519478083584",
    environment: process.env.REACT_APP_ENVIRONMENT,
    release: "carene-protect@" + process.env.REACT_APP_VERSION,
    dist: "1",
    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
    // We recommend adjusting this value in production.
    tracesSampleRate: 0.2,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.2,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new BrowserTracing({
        tracingOrigins: [
          "https://azdads-protect-dev.yzydigital.com",
          "https://espace.carene.fr",
        ],
      }),
      new Sentry.Replay(),
    ],
  });
}

if (process.env.REACT_APP_LOGS !== "true") {
  console.log = () => { };
  // console.debug = () => {};
  // console.warn = () => {};
  // console.error = () => {};
}

const params = new URLSearchParams(window.location.search);
const origin = params.get("origin");
const redirectTo = sessionStorage.getItem("redirectTo");

if (params.has("jwt")) {
  localStorage.setItem("token", params.get("jwt"));
}

if (origin === "AZDADS") {
  window.location.href = `${apiUrl}/sf/login`;
  localStorage.setItem("origin", origin);
  sessionStorage.setItem(
    "redirectTo",
    window.location.href.replace("origin=" + origin, "")
  );
} else if (redirectTo) {
  sessionStorage.removeItem("redirectTo");
  window.location.href = redirectTo;
} else {
  // @ts-ignore
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(<App />);
  // To restart
  // root.render(
  //   <React.StrictMode>
  //     <App />
  //   </React.StrictMode>
  // );
  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}
