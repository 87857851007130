import React, { useRef, useEffect } from 'react'
import { evalEvent, useTrackableState } from "../service/PageService";
import { Checkbox, Stack } from "@mui/material";
import LongLabel from "../components/LongLabel/LongLabel";

const Component = ({ registry, events, ...args }) => {
    const [label, setLabel] = useTrackableState(args.label || '');
    const [required, setRequired] = useTrackableState(args.required || false);
    const [error, setError] = useTrackableState(false);
    const [checked, setChecked] = useTrackableState(false);
    const [firstTime, setFirstTime] = useTrackableState(true);
    const [visible, setVisible] = useTrackableState(true);
    const ref = useRef();

    const validates = async () => {

        const state = {
            error: false,
            messages: []
        }

        if (firstTime) {
            setFirstTime(false);
            return state.error;
        }

        if (required && !checked) {
            state.error = true;
            state.messages.push("Case à cocher '" + label + "' obligatoire.");
        }

        await evalEvent(events, 'validate', {
            error: (msg) => {
                state.error = true;
                state.messages.push(msg);
            }
        });

        setError(state.error ? state.messages.join("\n") : false);

        return state;
    };

    useEffect(() => {
        validates().catch()
    }, [checked, required]);

    useEffect(() => {
        evalEvent(events, 'change').catch();
    }, [checked]);

    if (args.code) {

        const api = {
            'focus': () => { ref.current.focus() },
            'validates': validates,
        };
        Object.defineProperties(api, {
            'label': {
                get: () => label,
                set: (v) => setLabel(v)
            },
            'visible': {
                get: () => visible,
                set: (v) => setVisible(v)
            },
            'checked': {
                get: () => checked,
                set: (v) => setChecked(v)
            },
            'value': {
                get: () => checked,
                set: (v) => setChecked(v)
            },
            'required': {
                get: () => required,
                set: (v) => setRequired(v)
            },
            'error': {
                get: () => error,
                set: (v) => setError(v)
            }
        });

        registry.set(args.code, api);

    }

    if (!visible) return <></>

    return <Stack direction={"column"}>
        <Stack direction={"row"} alignItems={"center"}>
            <Checkbox checked={checked}
                inputRef={ref}
                onFocus={() => evalEvent(events, 'focus')}
                onClick={() => evalEvent(events, 'click')}
                onChange={(e) => setChecked(e.target.checked)}>
            </Checkbox>
            {label && <LongLabel
                noDots={true}
                label={label}
                //className={error?"LongLabel__Error":""}
                required={required}
                onClick={() => setChecked(!checked)} />}
        </Stack>
        {error && <LongLabel label={error} className={"LongLabel__Error"} noDots={true} />}
    </Stack>
}

export default Component;
