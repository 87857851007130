import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import { Box, Typography } from "@mui/material";

const Component = ({ registry, events, ...args }) => {
  const [text, setText] = useState(args.text || "");
  const [visible, setVisible] = useState(true);

  const api = {};
  if (args.code) {
    Object.defineProperties(api, {
      text: {
        get: () => text,
        set: (v) => setText(v),
      },

      visible: {
        get: () => visible,
        set: (v) => setVisible(v),
      },
    });

    registry.set(args.code, api);
  }

  if (!visible) return <></>;

  return (
    <Box
      sx={{
        "& ul": {
          listStyleType: "disc",
          marginLeft: 2,
        },
        "& li": {
          marginBottom: 1,
          position: "relative",
          "&::before": {
            content: '"•"',
            position: "absolute",
            left: -20,
          },
        },
      }}
    >
      <ReactMarkdown>{text}</ReactMarkdown>
    </Box>
  );
};

export default Component;
